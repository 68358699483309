import { GlobalObservableKey } from 'open-observable';
import { AuthResult } from 'auto/types/auth-result';
import { Permission } from 'auto/types/permission';

export const authStateKey = new GlobalObservableKey<AuthResult | undefined>('auth-state-key', {
      username: '',
      permission: 'Anonymous',
      csrf_token: '',
      token: '',
      nome: '',
      funcionario_id: '',
      clinica_id: '',
      foto: '',
});
export const authAccessToken = new GlobalObservableKey<
      | {
              access_token: string;
              authorized: boolean;
              permission: Permission;
              member_QS?: boolean;
              user_info?: any;
              access_info: any;
        }
      | undefined
>('access-token', { access_token: '', authorized: false, permission: 'Administrador', access_info: '' });
export const test = new GlobalObservableKey<{ access_token: string }>('test123', { access_token: '' });
