import { useGlobalObservable, useSubscriber } from 'open-observable';

import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { authAccessToken } from '../state/auth-state-key';
import { is_loading } from 'layout/base-layout/components/loading-fallback';

const getTokenUrlLogin =
      'https://qualidadeemsaudeorg.b2clogin.com/4c1b4978-a902-4648-88c4-4cb1c72fd0dd/oauth2/v2.0/token?p=b2c_1_cadastro_usuario';

const getTokenUrlRegister =
      'https://qualidadeemsaudeorg.b2clogin.com/4c1b4978-a902-4648-88c4-4cb1c72fd0dd/oauth2/v2.0/token?p=b2c_1_Create_User';

export async function getClinicaInfo({ accessToken }: { accessToken: string }) {
      const result = await fetch('https://api.qualidadeemsaude.com.br/erp/tenant/create/' as string, {
            credentials: 'include',
            method: 'GET',
            headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Connection: 'keep-alive',
                  Accept: '*/*',
                  'Accept-Encoding': 'gzip, deflate, br',
                  Host: 'qualidadeemsaudeorg.b2clogin.com',
                  Authorization: 'Bearer ' + accessToken,
            },
      });

      if (result.status === 405) return 405;
      if (!(result.status >= 200 && result.status <= 299)) {
            throw new Error(`Failed to get token: ${result.status} ${result.statusText}`);
      }


      const data = await result.json();
      return data;
}

export async function getAccessToken({ token, type }: { token: string; type: string }) {
      var details = {
            grant_type: 'authorization_code',
            client_id: '12d00f33-7f56-4440-96c1-2ae383fe85bb',
            scope: 'openid offline_access 12d00f33-7f56-4440-96c1-2ae383fe85bb profile',
            client_secret: 'Zf18Q~KWNpCO8lOqrb.JVbezypwtsYCrDU6bVb94',
            code: token,
      };

      var formBody: any = [];
      for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property as keyof typeof details]);
            formBody.push(encodedKey + '=' + encodedValue);
      }
      formBody = formBody.join('&');

      let url;

      if (type === 'login') url = getTokenUrlLogin;
      if (type === 'register') url = getTokenUrlRegister;

      const result = await fetch(url as string, {
            credentials: 'include',
            method: 'POST',
            headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Connection: 'keep-alive',
                  Accept: '*/*',
                  'Accept-Encoding': 'gzip, deflate, br',
                  Host: 'qualidadeemsaudeorg.b2clogin.com',
            },
            body: formBody,
      });

      if (!(result.status >= 200 && result.status <= 299)) {
            throw new Error(`Failed to get token: ${result.status} ${result.statusText}`);
      }

      const data = await result.json();
      return data;
}

export async function getPermissions({ accessToken }: { accessToken: string }) {
      const result = await fetch('https://api.qualidadeemsaude.com.br/cadastro/login/', {
            credentials: 'include',
            method: 'GET',
            headers: {
                  Authorization: 'Bearer ' + accessToken,
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Connection: 'keep-alive',
                  Accept: '*/*',
                  'Accept-Encoding': 'gzip, deflate, br',
                  Host: 'qualidadeemsaudeorg.b2clogin.com',
            },
      });
      if (result.status >= 400 && result.status <= 499) {
            localStorage.clear();
            return result.status;
      }
      if (!(result.status >= 200 && result.status <= 299)) {
            throw new Error(`Failed to get token: ${result.status} ${result.statusText}`);
      }

      const data = await result.json();

      return { ...data };
}

export const AuthState: FC = () => {
      const location = useLocation();
      const navigate = useNavigate();

      const urlParams = new URLSearchParams(window.location.search);
      const urlCode = urlParams.get('code');

      const inAuthPage = location.pathname.startsWith('/login');
      const inRegisterPage = location.pathname.startsWith('/register');
      const inInicioPage = location.pathname.startsWith('/vida/inicio');

      const token = useGlobalObservable(authAccessToken);

      const localStorageToken = localStorage.getItem('access_token');
      const localStorageAccessToken = localStorage.getItem('access_token');

      const inCriarERP = location.pathname.startsWith('/criar-erp');

      const loading = useGlobalObservable(is_loading);

      const getApiToken = async () => {
            loading.next(true)
            
            let type = 'login';

            if (inAuthPage) type = 'login';
            if (inCriarERP) type = 'register';

            if (urlCode && !localStorageAccessToken) {
                  localStorage.setItem('code', urlCode);
                  try {
                        const x = await getAccessToken({ token: urlCode, type });
                        localStorage.setItem('access_token', x.access_token);
                        localStorage.setItem('refresh_token', x.refresh_token);

                        const permissions = await getPermissions({ accessToken: x.access_token });
                        // const clinicaInfo = await getClinicaInfo({ accessToken: x.access_token });

                        // if (clinicaInfo !== 405) localStorage.setItem('clinica_id', clinicaInfo.clinica_id);

                        localStorage.setItem('user_name', permissions.full_name);

                        if (permissions !== 403) {
                              token.next({
                                    access_token: x.access_token,
                                    authorized: permissions.valid_whatsapp,
                                    permission: 'Administrador',
                                    // member_QS: permissions.member_QS,
                                    member_QS: true,
                                    user_info: permissions,
                                    access_info: ''
                              });

                              if (permissions.cpf !== null) return navigate('/vida/home');
                        } else {
                              token.next({
                                    access_token: '',
                                    authorized: false,
                                    permission: 'Administrador',
                                    access_info: ''

                              });
                              navigate('/login');
                        }
                  } catch (error) {
                        console.error('Error fetching access token:', error);
                  }
            }
            if (localStorageAccessToken && !urlCode) {
                  try {
                        const permissions = await getPermissions({ accessToken: localStorageAccessToken });

                        // const clinicaInfo = await getClinicaInfo({ accessToken: localStorageAccessToken });

                        // if (clinicaInfo !== 405) localStorage.setItem('clinica_id', clinicaInfo.clinica_id);

                        localStorage.setItem('user_name', permissions.full_name);
                       
                        if (permissions !== 403) {
                              token.next({
                                    access_token: localStorageAccessToken,
                                    member_QS: true,
                                    user_info: permissions,
                                    authorized: true,
                                    permission: 'Administrador',
                                    access_info: ''
                              });

                              // if (clinicaInfo === 405) return navigate('/criar-erp');
                        } else {
                              token.next({
                                    access_token: '',
                                    authorized: false,
                                    permission: 'Administrador',
                                    access_info: ''

                              });
                              navigate('/login')
                        }
                  } catch (error) {
                        console.error('Error fetching access token:', error);
                  }
            }
      };

      const tokenValue = useSubscriber(token);

      React.useEffect(() => {
            if (tokenValue?.member_QS === false) {
                  return navigate('/criar-erp');
            }
      }, [tokenValue, urlCode]);

      React.useEffect(() => {
            getApiToken();
      }, [token, urlCode]);

      React.useEffect(() => {
            loading.next(false)     

            if (localStorageToken && (inAuthPage || inInicioPage) && tokenValue?.user_info?.cpf != null) {
                  // token.next({ access_token: localStorageToken, authorized: true, permission: 'Administrador',  access_info: '' });
                  return navigate('/vida/home');
            }
            if (localStorageToken && inAuthPage) {
                  token.next({ access_token: localStorageToken, authorized: true, permission: 'Administrador',  access_info: '' });
                  return navigate('/vida/inicio');
            }

            if (inCriarERP && tokenValue?.member_QS === true) {
                  return navigate('/vida/inicio');
            }

            if (!localStorageToken && !inRegisterPage) {
                  return navigate('/login');
            }
      }, [inAuthPage, localStorageToken, tokenValue, inCriarERP]);

      return null;
};
