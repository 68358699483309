import { createPages, mergePages } from 'layout/base-layout/other/create-pages';

import { lazy } from 'react';

const Modals = lazy(() => import('components/Modals'));
const Theme = lazy(() => import('components/ThemeContext'));
const NotificationsRenderer = lazy(() => import('components/alerts/notifications'));
const TopBar = lazy(() => import('layout/ERP/navbar/TopBar'));

const Home = lazy(() => import('layout/HomeMain/'));
const LoginView = lazy(() => import('layout/viewsTenant/autenticacao'));
const RegisterView = lazy(() => import('layout/viewsTenant/register'));
const AuthLayout = lazy(() => import('layout/auth/components/'));
const ANU_dashboard = lazy(() => import('layout/Home/Dashboard'));

const LEX_dashboard = lazy(() => import('layout/LEX/AuthLayout'));
const LexMain = lazy(() => import('layout/LEX'));
const ContractHolder = lazy(() => import('layout/LEX/FormularioLexContratos/components/contractHolder'));

const CONTABIL_dashboard = lazy(() => import('layout/Contabil/AuthLayout'));
const Contabil_home = lazy(() => import('layout/Contabil'));
const Contabil_nfe = lazy(() => import('layout/Contabil/NFe'));

const ERP_dashboard = lazy(() => import('layout/ERP/AuthLayout'));
const TelaInicial = lazy(() => import('layout/ERP/home'));
const EditarCriarAgendamento = lazy(() => import('layout/ERP/agendamento'));
const ListaAgendamento = lazy(() => import('layout/ERP/agendamento/ListaAgendamento'));
const ClinicaCadastro = lazy(() => import('layout/ERP/clinica/Cadastro'));
const Infraestrutura = lazy(() => import('layout/ERP/clinica/Infraestrutura'));
const LocalizadorQS = lazy(() => import('layout/ERP/clinica/LocalizadorQS'));
const EditarFinanceiroEntradaSaida = lazy(() => import('layout/ERP/financeiro'));
const ListaFinanceiroEntrada = lazy(() => import('layout/ERP/financeiro/entrada/ListaFinanceiroEntrada'));
const ListaFinanceiroSaida = lazy(() => import('layout/ERP/financeiro/saida/ListaFinanceiroSaida'));
const EditarCadastroFuncionarios = lazy(() => import('layout/ERP/funcionario'));
const ListaFuncionarios = lazy(() => import('layout/ERP/funcionario/ListaFuncionarios'));
const DevTests = lazy(() => import('layout/ERP/dev-tests'));
const EditarMateriais = lazy(() => import('layout/ERP/materiais'));
const ListaMateriais = lazy(() => import('layout/ERP/materiais/ListaMateriais'));
const EditarCadastroOrcamentos = lazy(() => import('layout/ERP/orcamentos'));
const ListaOrcamentos = lazy(() => import('layout/ERP/orcamentos/ListaOrcamentos'));
const EditarCadastroPacientes = lazy(() => import('layout/ERP/pacientes'));
const ListaPacientes = lazy(() => import('layout/ERP/pacientes/ListaPacientes'));
const EditarCadastroContatos = lazy(() => import('layout/ERP/contatos'));
const ListaContatos = lazy(() => import('layout/ERP/contatos/ListaContatos'));
const EditarCadastroProtocolos = lazy(() => import('layout/ERP/protocolos'));
const ListaProtocolos = lazy(() => import('layout/ERP/protocolos/ListaProtocolos'));
const EditarCadastroTratamentosModelo = lazy(() => import('layout/ERP/tratamentos'));
const ListaTratamentosModelo = lazy(() => import('layout/ERP/tratamentos/ListaTratamentosModelo'));
const ListaDocumentos = lazy(() => import('layout/ERP/documentos/ListaDocumentos'));
const EditarCadastroDocumentos = lazy(() => import('layout/ERP/documentos'));
const OmniChat = lazy(() => import('layout/ERP/omniChat'));
const Prontuario = lazy(() => import('layout/ERP/prontuario'));
const EditarCadastrarProntuario = lazy(() => import('layout/ERP/prontuario/FormularioProntuario'));
const Campanha = lazy(() => import('layout/ERP/campanha'));
const SafeId = lazy(() => import('layout/ERP/safeid'));
const SafeIdView = lazy(() => import('layout/ERP/safeid/Visualizacao'));
const CadastroUsuario = lazy(() => import('layout/ERP/CriarUsuario'));
const CardUsuario = lazy(() => import('layout/ERP/CriarUsuario/CardUsuario'));
const CardProfissional = lazy(() => import('layout/ERP/CriarUsuario/CardProfissional'));
const CardClinica = lazy(() => import('layout/ERP/CriarUsuario/CardClinica'));
const ProfissionalProfile = lazy(() => import('layout/Engineer/profissionalProfile'));
const CriarPerfilProfissional = lazy(() => import('layout/ERP/CriarUsuario/criarPerfilProfissional'));
const Inicio = lazy(() => import('layout/ERP/CriarUsuario/inicio'));
const DrQs_dashboard = lazy(() => import('layout/DrQs/AuthLayout'));
const DrQsMain = lazy(() => import('layout/DrQs'));
const DrQsAgenda = lazy(() => import('layout/DrQs/Agenda'));
const DrQsPipeline = lazy(() => import('layout/DrQs/Pipeline'));
const DrQsChat = lazy(() => import('layout/DrQs/Chat'));
const PageCreator = lazy(() => import('layout/ERP/pageCreator/pages'));
const CriarUnidade = lazy(() => import('layout/ERP/criarUnidade'));
const CriarUnidadeVirtual = lazy(() => import('layout/ERP/criarUnidadeVirtual'));
const CustomDashboard = lazy(() => import('layout/ERP/criarUnidade/dashboard'));
const CompanyProfile = lazy(() => import('layout/Engineer/companyProfile'));
const CriarFuncionarios = lazy(() => import('layout/ERP/criarFuncionarios'));

const Authentication = lazy(() => import('other/Authentication'));
const Autenticacao = lazy(() => import('layout/viewsTenant/autenticacao'));

export const pages = mergePages(
      createPages(AuthLayout, {
            // '/': [LoginView],
            '/login': [LoginView],
            '/register': [RegisterView],
      }),
      createPages(CustomDashboard, {
            '/contabil': [Contabil_home],
      }),

      createPages(ANU_dashboard, {
          
            // '/': [Home, ['Administrador']],
      }),
      createPages(ERP_dashboard, {
            //vida
            '/vida/cadastro/:id': [CadastroUsuario, ['Administrador']],
            '/vida/card': [CardUsuario, ['Administrador']],
            '/vida/home': [Home, ['Administrador']],
            '/vida/inicio': [Inicio, ['Administrador']],

            //drqs
            '/drqs/home': [Home, ['Administrador']],

            '/drqs/profissional-profile/:id': [ProfissionalProfile, ['Administrador']],
            '/drqs/criar-profissional-profile': [CriarPerfilProfissional, ['Administrador']],
            '/drqs/profissional-profile-card': [CardProfissional, ['Administrador']],
            '/drqs/clinica-profile-card': [CardClinica, ['Administrador']],
            '/drqs/company-profile/:id': [CompanyProfile, ['Administrador']],
            '/drqs/criar-unidade': [CriarUnidade, ['Administrador']],
            '/drqs/criar-funcionarios': [CriarFuncionarios, ['Administrador']],
            '/drqs/page-creator': [PageCreator, ['Administrador']],
            '/drqs/criar-unidade-virtual': [CriarUnidadeVirtual, ['Administrador']],
            
            //erp
            '/erp/home': [TelaInicial, ['Administrador']],
            '/erp/clinica/:id': [ClinicaCadastro, ['Administrador']],
            '/erp/agendamento': [ListaAgendamento, ['Administrador']],
            '/erp/agendamento/:id': [EditarCriarAgendamento, ['Administrador']],
            '/erp/financeiro/entrada': [ListaFinanceiroEntrada, ['Administrador']],
            '/erp/financeiro/entrada/:id': [EditarFinanceiroEntradaSaida, ['Administrador']],
            '/erp/financeiro/saida': [ListaFinanceiroSaida, ['Administrador']],
            '/erp/financeiro/saida/:id': [EditarFinanceiroEntradaSaida, ['Administrador']],
            '/erp/:app/documentos': [ListaDocumentos, ['Administrador']],
            '/erp/documentos/:id': [EditarCadastroDocumentos, ['Administrador']],
            '/erp/funcionarios/cadastrados': [ListaFuncionarios, ['Administrador']],
            '/erp/funcionarios/cadastrados/:id': [EditarCadastroFuncionarios, ['Administrador']],
            '/erp/materiais': [ListaMateriais, ['Administrador']],
            '/erp/materiais/:id': [EditarMateriais, ['Administrador']],
            '/erp/materiais/cadastrar/:type': [EditarMateriais, ['Administrador']],
            '/erp/orcamentos': [ListaOrcamentos, ['Administrador']],
            '/erp/orcamentos/:id': [EditarCadastroOrcamentos, ['Administrador']],
            '/erp/pacientes/cadastrados': [ListaPacientes, ['Administrador']],
            '/erp/pacientes/cadastrados/:id': [EditarCadastroPacientes, ['Administrador']],
            '/erp/contatos': [ListaContatos, ['Administrador']],
            '/erp/contatos/:id': [EditarCadastroContatos, ['Administrador']],
            '/erp/protocolos': [ListaProtocolos, ['Administrador']],
            '/erp/protocolos/:id': [EditarCadastroProtocolos, ['Administrador']],
            '/erp/tratamentos': [ListaTratamentosModelo, ['Administrador']],
            '/erp/tratamentos/:id': [EditarCadastroTratamentosModelo, ['Administrador']],
            '/erp/dev-tests': [DevTests, ['Administrador']],
            '/erp/:app/agenda/:year/:month/:day': [DrQsAgenda, ['Administrador']],
            '/erp/:app/pipeline': [DrQsPipeline, ['Administrador']],
            '/erp/flow': [DrQsPipeline, ['Administrador']],
            '/erp/chat': [OmniChat, ['Administrador']],
            '/erp/prontuario': [Prontuario, ['Administrador']],
            '/erp/prontuario/:id': [EditarCadastrarProntuario, ['Administrador']],
            '/erp/campanha': [Campanha, ['Administrador']],
            '/erp/legal/contratos/cadastrados': [LexMain, ['Administrador']],
            '/erp/legal/contratos/cadastrar/:id': [ContractHolder, ['Administrador']],
            '/erp/contabil/nfe': [Contabil_home],
            '/erp/contabil/nfe/cadastrar': [Contabil_nfe],
            '/erp/legal/safeid': [SafeId, ['Administrador']],
            '/erp/legal/safeid/:id': [SafeIdView, ['Administrador']],
      }),
      createPages(LEX_dashboard, {}),
      createPages(DrQs_dashboard, {
            '/drqs': [DrQsMain, ['Administrador']],
            '/drqs/agenda/:year/:month/:day': [DrQsAgenda, ['Administrador']],
            '/drqs/pipeline': [DrQsPipeline, ['Administrador']],
            '/drqs/chat': [DrQsChat, ['Administrador']],
      }),
);